/*
 * @Author: your name
 * @Date: 2020-05-29 16:19:50
 * @LastEditTime: 2020-06-24 18:24:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /koala-cli/src/main.js
 */
import Vue from "vue";
import App from "./app.vue";
import router from "./router/index.js";
import * as $axios from "./api/index.js";
import * as global from "@/utils/global.js";
import "babel-polyfill";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

import Koala from "@koala/vue-koala";
import "@koala/vue-koala/dist/css/koala.css";
Vue.use(Koala);
/**vuex */
/**vuex */

// 线上环境及demos环境使用
if (process.env.RUN_ENV == "prod") {
  Sentry.init({
    // dsn: window.location.protocol + '//f1bdb0a703814162b3e2b07d82350c08@' + window.location.host + '/36',
      dsn: "https://aa2143e7754b4e508fd711c52cd6236e@sentrypublic.51shebao.com/7",
      integrations: [
          new Integrations.Vue({
              Vue,
              attachProps: true
          })
      ],
      tracesSampleRate: 1.0,
      release: "homehiwork@" + process.env.VERSION
  });
}
// Vue.use(Koala)
import { imgUrl } from "@/utils/url.js";
Vue.prototype.$axios = $axios;
Vue.prototype.$message = Koala.Message;
Vue.prototype.$imgUrl = imgUrl;
// Vue.prototype.$utils = $utils;
//Sentry 错误消息监控
if (process.env.NODE_ENV == "development" && process.env.RUN_ENV != "test") {
  let url = window.location.host;
  Sentry.init({
    // dsn: 'https://f622cc7b018646dbbfb19490b2587b6b@' + url + '/40',
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
  });
}

// 存储 销售id
if (global.getUrlParam("saleNumber") || global.getUrlParam("rcm")) {
  localStorage.setItem(
    "SaleNumber",
    global.getUrlParam("saleNumber") || global.getUrlParam("rcm")
  );
} else {
  localStorage.setItem("SaleNumber", "");
}

// //来源
if (global.getUrlParam("source")) {
  localStorage.setItem("referrerSource", global.getUrlParam("source"));
} else {
  localStorage.setItem("referrerSource", "");
}
if (global.getUrlParam("cuid")) {
  localStorage.setItem("cUid", global.getUrlParam("cuid"));
} else {
  localStorage.setItem("cUid", "");
}
router.afterEach((to, from, next) => {
  console.log(to)
  const {path} = to
  if(!['/inner/agreement','/login','/register'].includes(path)){
    $axios.qiMoorParams().then((res) => {
      if (res.code == 22000) {
        udesk(res.result);
      } else {
        udesk();
      }
    });
  }
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 0);
});

function udesk(data) {
  (function(a, h, c, b, g) {
    var nosignScript = document.querySelector("#nosign");
    if (nosignScript) {
      return;
    }
    data
      ? (a["qimoClientId"] = {
          userId: data.id + "_" || "", // 自定义用户的唯一id，不能传空字符串、null、中文，id由字母大小写、数字及'-'和 '_'组成
          customField: {
            // 扩展字段，json对象，对象中的key-value都自定义
            商户ID: data.companyId,
            商户名称: data.userName,
            操作人ID: data.id,
          },
        })
      : (a["qimoClientId"] = Math.random() + "_");
    g = h.createElement(c);
    g.async = 1;
    g.charset = "utf-8";
    g.src = b;
    g.setAttribute("id", "nosign");
    c = h.getElementsByTagName(c)[0];
    c.parentNode.insertBefore(g, c);
  })(
    window,
    document,
    "script",
    "https://webchat.7moor.com/javascripts/7moorInit.js?accessId=594db820-19b6-11eb-b02a-952f3c780bab&autoShow=true&language=ZHCN",
    "ud"
  );
};

new Vue({
  router,
  // mounted() {},
  render: (h) => h(App),
}).$mount("#app");
