<!--
 * @Author: your name
 * @Date: 2020-05-29 16:19:50
 * @LastEditTime: 2020-06-09 14:00:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /koala-cli/src/app.vue
-->
<template>
  <div id="app" class="" :style="{height: windowHeight+'px'}">
    <router-view />
  </div>
</template>
<script>
import outdatedBrowserRework from './asset/js/outdated-browser-rework/outdated-browser-rework.js';
outdatedBrowserRework({
  fullscreen: false,
  browserSupport: {
    'Edge': 12,
    'Chrome': 49,
    'Safari': 10,
    'Firefox': 36
  }
});
export default {
  name: 'App',
  data() {
    return {
      windowHeight: document?.documentElement.scrollHeight
    };
  },
  created() {
    this.updateWindowHeight();
    window.addEventListener('resize', this.updateWindowHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowHeight);
  },
  methods: {
    updateWindowHeight() {
      this.windowHeight = document?.documentElement.scrollHeight;
    }
  }
};
</script>
<style lang="scss">
@import './asset/css/index.scss';
@import './asset/js/outdated-browser-rework/outdated-browser-rework.min.scss';
#app {
  // min-height: 600px;
  // overflow-y: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
