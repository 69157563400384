const md5 = require("js-md5");
const getUrlParam = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1)
    ? window.location.search.substr(1).match(reg)
    : null;
  if (r != null) return unescape(r[2]);
  return null;
};

const encodeFun = (params) => {
  let str = "";
  for (var attr in params) {
    str += "-" + attr + "-" + params[attr];
  }
  // console.log(`0E77402CB7FF5633${str}`,'strrrrrrrr');
  let obj = Object.assign({}, params, { sign: md5(`0E77402CB7FF5633${str}`) });
  return obj;
};
export { getUrlParam, encodeFun };
