const urlByEnv = {
  dev: {
    income: 'http://test-e.hiwork.com',
    home: 'https://test-home.101hr.com',
    ssoGlobal: 'http://test-home.hiworkglobal.com',
  },
  test: {
    income: 'http://test-e.hiwork.com',
    home: 'https://test-home.101hr.com',
    ssoGlobal: 'http://test-home.hiworkglobal.com',
  },
  qa:{
    income: 'http://qa-e.hiwork.com',
    home: 'https://qa-home.101hr.com',
    ssoGlobal: 'http://qa-home.hiworkglobal.com',
  },
  demos: {
    income: 'https://demos-e.hiwork.com',
    home: 'https://demos-home.101hr.com',
    ssoGlobal: 'https://home-global.hiwork.com',
  },
  prod: {
    income: 'https://e.hiwork.com',
    home: 'https://home.101hr.com',
    ssoGlobal: 'https://home.hiworkglobal.com',
  }
};
const imgUrl =
  process.env.RUN_ENV === 'prod'
    ? 'https://sthome.hiwork.com/static/img/'
    : '/static/img/';
const env = window.location.host.includes('qa') ? 'qa' : window.location.host.includes('demos') ? 'demos' :  process.env.RUN_ENV;
export { urlByEnv, env, imgUrl };
