import axios from 'axios';
// import router from "../../node_modules/vue-router"; // 引入router
import router from '@/router';
import { Message } from '@koala/vue-koala';
let token = localStorage.getItem('Token') ? localStorage.getItem('Token') : '';

const runEnv = process.env.RUN_ENV;
// axios.defaults.baseURL = '/';

const service = axios.create({
  baseURL: '',
  timeout: 5000,
  headers: {
    token: token
  }
});

let pendingAjax = [];
const fastClickMsg = '数据请求中，请稍后';
const CancelToken = axios.CancelToken;
const removePendingAjax = (url, type) => {
  const index = pendingAjax.findIndex((i) => i.url === url);
  if (index > -1) {
    type === 'req' && pendingAjax[index].c(fastClickMsg);
    pendingAjax.splice(index, 1);
  }
};

// 请求拦截 req 数据处理
service.interceptors.request.use(
  (config) => {
      config.headers.token = localStorage.getItem('Token') || '';
    /* 取消请求 */
    const url = config.url;
    // removePendingAjax(url, 'req');
    config.cancelToken = new CancelToken((c) => {
      pendingAjax.push({
        url,
        c
      });
    });
    /* 取消请求 */
    return config;
  },
  (error) => {
    console.log('axios请求报错:', error);
  }
);

service.interceptors.response.use(
  (response) => {
    removePendingAjax(response.config.url, 'resp');
    let _D = response.data;
    if (_D.code == 22001 || _D.code == 1000) {
      // 基本的校验没通过 || 后端代码有bug
      // if(!vm.$route.meta.nosktip){
      Message.error(_D.message);
      // }
    }
    // if (_D.code == 22002) {
    //   localStorage.removeItem('Token');
    //   localStorage.removeItem('isAdmin');
    //   localStorage.removeItem('isAllowNewsalary');
    //   router.push('/login');
    // }
    return Promise.resolve(_D);
  },
  (error) => {
    console.log(error, 'message');
    if (error.response && error.response.status) {
      switch (error.response.status) {
          // 401: 未登录
          // 未登录则跳转登录页面，并携带当前页面的路径
          // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath
            }
          });
          break;
          // 403 token过期
          // 登录过期对用户进行提示
          // 清除本地token和清空vuex中token对象
          // 跳转登录页面
        case 403:
          Message.error('登录过期，请重新登录'); // 产品提供文案
            // 清除token
          localStorage.removeItem('Token');
            // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          setTimeout(() => {
            router.replace({
              path: '/login',
              query: {
                redirect: router.currentRoute.fullPath
              }
            });
          }, 1000);
          break;
          // 404请求不存在
        case 404:
          Message.error('网络请求不存在');
          break;
          // 请求方法错误
        case 405:
          Message.error('请求方法错误');
          break;
        case 500:
          Message.error('服务端出错');
          break;
        case 501:
          Message.error('网络未实现');
          break;
        case 502:
          Message.error('网络错误');
          break;
        case 503:
          Message.error('服务不可用');
          break;
        case 504:
          Message.error('网络超时');
          break;
        case 505:
          Message.error('http版本不支持该请求');
          break;
          // 其他错误，直接抛出错误提示
        default:
          Message.error('error.response.data.message');
      }
    }
    return Promise.reject(error.response);
  }
);
function req(method, apiName, data = null, headers) {
  // Base_Url
  method = method.toLowerCase();
  if (method == 'post') {
    // 文件上传 采用 单独设置header Content-Type:multipart/form-data 方式
    return service.post(apiName, data, {
      headers: {
        'Content-Type': headers || 'application/json;charset=utf-8'
      }
      // withCredentials: true
    });
  } else if (method == 'get') {
    return service.get(apiName, { params: data });
  }
}
export default req;
