import Vue from "vue";
import VueRouter from "vue-router";
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
Vue.use(VueRouter);
// import PersonalSetting from '@/views/personalSetting/index.vue'

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index.vue"),
    redirect: "login",
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/views/login/login.vue"),
      },
      {
        path: "register",
        name: "register",
        component: () => import("@/views/login/register.vue"),
      },
      {
        path: "reset",
        name: "reset",
        component: () => import("@/views/reset/index.vue"),
      },
      {
        path: "passwordSet",
        name: "passwordSet",
        component: () => import("@/views/password/set.vue"),
      },
      {
        path: "inner/joinAccount",
        name: "joinAccount",
        component: () => import("@/views/inner/joinAccount.vue"),
      },
      {
        path: "inner/agreement",
        name: "agreement",
        component: () => import("@/views/personalSetting/agreement.vue"),
        // meta: {
        //   title: '协议',
        //   // backUrl: '/setting/personal'
        // }
      },
    ],
  },
  {
    path: "/setting",
    component: () => import("@/views/component/topHeader.vue"),
    redirect: "/setting/personal",
    children: [
      {
        path: "company/info",
        name: "companyInfo",
        component: () => import("@/views/company/info.vue"),
        meta:{
          title: '企业信息管理',
          belong: '企业设置'
        }
      },
      
      {
        path: "company/accountManagement",
        name: "companyAccount",
        component: () => import("@/views/company/accountManagement.vue"),
        meta:{
          title: '账号管理',
          belong: '企业设置'
        }
      },
      {
        path: "company/addAccount",
        name: "companyAddAccount",
        component: () => import("@/views/company/addAccount.vue"),
        meta:{
          title: '添加账号',
          belong: '企业设置',
          backUrl: '/setting/company/accountManagement'
        }
      },
      {
        path: "company/transferAccount",
        name: "companyTransferAccount",
        component: () => import("@/views/company/transferAdministrator.vue"),
        meta:{
          title: '转让管理员',
          belong: '企业设置',
          backUrl: '/setting/company/accountManagement'
        }
      },
      {
        path: "company/transferSuccess",
        name: "companyTransferSuccess",
        component: () => import("@/views/company/transferSuccess.vue"),
        meta:{
          title: '企业管理员转让',
          belong: '企业设置',
          backUrl: '/setting/company/accountManagement'
        }
      },
      {
        path: "company/inviteSuccess",
        name: "companyInviteSuccess",
        component: () => import("@/views/company/inviteSuccess.vue"),
        meta:{
          title: '',
          belong: '企业设置',
        }
      },
      // {
      //   path: "personal",
      //   name: "personal_setting",
      //   component: () => import("@/views/personalSetting/index.vue"),
      //   meta: {
      //     title: '个人设置',
      //     belong: '个人设置',
      //   }
      // },
      {
        path: "personal/updatePhone",
        name: "update_phone",
        component: () => import("@/views/personalSetting/updatePhone.vue"),
        meta: {
          title: '修改手机号码',
          backUrl: '/setting/personal'
        }
      },
      {
        path: "personal/updateEmail",
        name: "update_email",
        component: () => import("@/views/personalSetting/updateEmail.vue"),
        meta: {
          title: '修改邮箱',
          backUrl: '/setting/personal'
        }
      },
      // {
      //   path: "personal/setEmail",
      //   name: "set_email",
      //   component: () => import("@/views/personalSetting/setEmail.vue"),
      //   meta: {
      //     title: '设置邮箱',
      //     backUrl: '/setting/personal'
      //   }
      // },
      {
        path: "personal/updatePassword",
        name: "update_password",
        component: () => import("@/views/personalSetting/updatePassword.vue"),
        meta: {
          title: '修改登录密码',
          backUrl: '/setting/personal'
        }
      },
      {
        path: "personal/verifyEmail",
        name: "verify_email",
        component: () => import("@/views/personalSetting/verifyEmail.vue"),
        meta: {
          title: '验证邮箱',
          backUrl: '/setting/personal'
        }
      },
     
    ],
  },
  {
    path: "/accountLogout",
    name: "accountLogout",
    component: () => import("@/views/account/accountLogout.vue"),
  },
  {
    path: "/logoutCreateCompany",
    name: "logoutCreateCompany",
    component: () => import("@/views/account/logoutCreateCompany.vue"),
  },
  {
    path: "*",
    redirect: "login",
  },
];
const router = new VueRouter({
  mode: "history",
  fallback: false,
  routes,
});
export default router;
