import req from '@/utils/request';
// const storageToken = localStorage.getItem('Token') ? localStorage.getItem() : '';
const checkToken = (params) => req('get', '/api/token', params);
const isLogin = (params) => req('get', '/api/isLogin', params);
// 七陌客服
const qiMoorParams = (params) => req('get', `/api/qiMoorParams`, params);
// 验证手机号是否注册
const isRegister = (params) => req('get', `/api/isRegister`, params); // sign
// 获取短信验证码
const getBasicSms = (params) => req('post', '/api/sms', params);
// 获取语音验证码
const getVoiceSms = (params) => req('post', '/api/sendVoice', params);
// 密码登录
const loginByPassWord = (params) => req('post', '/api/loginByPwd', params); // sign
// 验证码登录
const loginBySms = (params) => req('post', '/api/loginBySms', params);
// 取阿里云滑块验证appkey、scene
const getNcParams = (params) => req('get', '/api/getNcParams', params);
// 获取绑定的企业列表
const getCompany = (params) => req('get', '/api/account/withCompanys', params);
// 切换企业登录
const changeCompany = (params) =>
  req('post', '/api/account/choseCompany', params);
// 重置密码
const resetPassword = (params) => req('post', '/api/password', params);
const toRegister = (params) => req('post', '/api/register', params);
const isShowPrivateAgreement = params => req('get', `/api/user/isShowPrivateNeg`, params);// 是否展示隐私政策弹框

// 获取可转让企业成员
export const getCanAssignMembers = params => req('get', '/api/user/getCanAssignMembers', params);
// 获取绑定企业列表（包含角色信息）
export const bindCompanyDetail = params => req('get', '/api/account/bindCompanyDetail', params);
// 基本信息
export const baseInfo = params => req('get', '/api/account/baseInfo', params);
// 获取微信的绑定状态
export const getWxBindStatus = params => req('get', '/api/wechat/getBindStatus', params);
// 绑定微信
export const qrCode = params => req('get', '/api/wechat/qrCode', params);
// 修改姓名
export const editName = params => req('post', '/api/account/editName', params);
// 全业务线管理员 退出企业
export const unbind = params => req('post', '/api/account/unbind', params);
// 获取用户信息
export const getInfo = params => req('get', `/api/getInfo`, params);// 获取公司信息
// 获取短信验证码
export const sendCodeToPhone = params => req('post', '/api/account/sendCodeToPhone', params);
// 校验验证码是否正确
export const checkPhoneCode = params => req('post', '/api/account/checkPhoneCode', params);
// 校验密码是否正确
export const checkPwd = params => req('post', '/api/account/checkPwd', params);
// 获取语音验证码
export const sendVoiceCode = params => req('post', '/api/sendVoice', params);
// /api/account/updatePhoneSendCode
export const updatePhoneSendCode = params => req('post', '/api/account/updatePhoneSendCode', params);
// 编辑手机号
export const editPhone = params => req('post', '/api/account/phone/edit', params);
// 修改邮箱
export const editEmail = params => req('post', '/api/account/editEmail', params);
// 退出登录
export const logout = params => req('get', '/api/logout', params);
// 发送邮箱验证码
export const sendCodeToMail = params => req('post', '/api/account/sendCodeToMail', params);
// /api/account/checkEmail
export const checkEmail = params => req('post', '/api/account/checkEmail', params);
// 重置密码
export const password = params => req('post', '/api/password', params);
// 创建企业
export const bindCompany = params => req('post', `/api/account/bindCompany`, params);

// 获取公司列表
const getCompanies = params => req('get', '/api/account/withCompanys', params);
// 获取当前登录人信息
const getLoginUserDetail = params => req('get', '/api/account/baseInfo', params);
// 获取企业详情
const companyDetail = params => req('get', '/api/customer/detail', params);
// 修改企业信息
const editCompanyInfo = params => req('post', '/api/customer/edit', params);
// 获取企业成员
const companyUsers = params => req('get', '/api/account/index', params);
// 判断手机号、邮箱是否已经绑定企业
const isBindCompany = params => req('get', '/api/user/isBindCompany', params);
// 添加成员
const inviteUser = params => req('post', '/api/user/inviteUser', params);
// 企业可选角色列表
const roleList = params => req('get', '/api/company/roleList', params);
// 删除角色
const deleteRole = params => req('post', '/api/account/delete', params);
// 获取可转让的角色
const getTransferRoles = params => req('get', '/api/user/getCanAssignMembers', params);
// 转让管理员
const transferRole = params => req('post', '/api/user/assignToMember', params);
// 获取用户邀请记录
const userInviteDetail = params => req('get', '/api/invite/invitationRecord/getInviteInfo', params);
// 切换公司
const changeCompanyLogin = params => req('post', '/api/account/changeCompany', params);
// 是否有权限查看企业设置
const isAdminCompany = params => req('get', '/api/account/getTopInfo', params);
// 消息通知
const messageAllList = params => req('get', `/api/message/list`, params); // 获取消息列表
// 设置消息已读 本次迁移用的home项目的接口  没有单个读消息的接口 只能用 读多个消息接口
const readMessages = params => req('post', `/api/message/reads`, params); // 获取消息列表
// 获取ticket
const getTicket = params => req('get', '/api/check/login', params);
// 换取新的ticket
const getNewTicket = params => req('get', '/api/set/login', params);

const getTokenAndTicket = params => req('post', '/api/getTokenAndTicket', params);
// 登录是否勾选过
const getNeedShowTips = params => req('post', '/api/tips/needShowTips', params);
// 公共弹窗确认
const getHasAffirm = params => req('post', '/api/tips/hasAffirm', params);
const setPassword = params => req('post', `/api/setPassword`, params);// 设置密码 password repassword
// 是否开启水印
const companyWatermark = params => req('post', `/api/company/watermark`, params);
// 获取受邀人数据
const getInviteUserInfo = params => req('get', '/join/api/invite/userInfo', params);
// 发送短信验证码
const getInviteSendSms = params => req('post', '/join/api/invite/sendSms', params);

// 发送语音验证码
const getInviteSendVoice = params => req('post', '/join/api/invite/sendVoice', params);

// 用户接受邀请加入企业
const setUserInviteJoin = params => req('post', '/join/api/invite/join', params);

export {
  companyWatermark,
  checkToken,
  isLogin,
  qiMoorParams,
  isRegister,
  getBasicSms,
  getVoiceSms,
  loginByPassWord,
  loginBySms,
  getNcParams,
  getCompany,
  changeCompany,
  resetPassword,
  toRegister,
  isShowPrivateAgreement,
  getCompanies,
  getLoginUserDetail,
  companyDetail,
  editCompanyInfo,
  companyUsers,
  isBindCompany,
  inviteUser,
  roleList,
  deleteRole,
  getTransferRoles,
  transferRole,
  userInviteDetail,
  changeCompanyLogin,
  isAdminCompany,
  messageAllList,
  readMessages,
  getTicket,
  getNewTicket,
  getTokenAndTicket,
  getNeedShowTips,
  getHasAffirm,
  setPassword,
  getInviteUserInfo,
  getInviteSendSms,
  getInviteSendVoice,
  setUserInviteJoin
};
